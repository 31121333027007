img {
  width: 300px;
}

.plot div {
  text-align: center;
}

/* HACK: plots need to be moved from the side bar but because of their data importance to the game finishing, right now they are being positioned really awkwardly */
.plots {
  position: absolute;
  top: 0;
  margin-top: 350px;
  right: 0;
  margin-right: 24px;
  z-index: 10;
  background: white;
  height: 400px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.plotsHidden {
  display: none;
}

.service-count-wrap {
  width: 400px;
}
