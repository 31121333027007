.game-wrap {
  display: grid;
  grid-template-columns: 300px auto 380px;
  /* margin: 12px; */
  grid-gap: 12px;
}

.demo-wrap {
  display: grid;
  grid-template-columns: 300px auto 50px;
  grid-gap: 12px;
}

.lite-label {
  margin-top: -24px;
  margin-bottom: 8px;
  margin-left: 16px;
}

.levelModal {
  position: absolute;
  inset: 160px;
  background: white;
  padding: 40px;
  border: 1px solid grey;
  box-shadow: 3px 3px 5px #535353;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  overflow: auto;
}

.glossaryModal {
  position: absolute;
  inset: 160px;
  background: white;
  padding: 4px;
  border: 1px solid grey;
  box-shadow: 3px 3px 5px #535353;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  overflow: auto;
}

.levelModal p {
  font-size: 14px;
  line-height: 20px;
  max-width: 80%;
}

.levelOverModal {
  inset: initial;
  height: 100%;
  display: block;
  max-width: 100%;
  width: 100%;
  padding: 0px;
}

.postDataWrap {
  display: flex;
  flex-wrap: wrap;
}

.level-header {
  font-size: 24px;
  grid-column: 1 / span 2;
  font-weight: 500;
  text-align: center;
}
.sidebar-wrap .container {
  width: 270px;
  position: relative;
  margin: 12px;
  z-index: 1;
  padding: 12px 12px;
  max-height: 450px;
}

.sidebar-wrap > div:nth-child(1) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.sidebar-wrap > div:nth-child(1) .btn--primary {
  grid-column: 1 / span 2;
}

.sidebar-wrap > div:nth-child(1) a {
  grid-column: 1 / span 2;
}

.sidebar-wrap a .btn {
  width: 100%;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}

.sidebar-wrap button {
  margin: 4px;
}

.sidebar-wrap img {
  position: absolute;
  width: 100%;
  margin: -34px 0 0 -12px;
  height: 210px;
  object-fit: cover;
  border-radius: 0px;
}

.sidebar-wrap h2 {
  padding-top: 190px;
}

.sidebar-wrap p {
  margin-bottom: 0px;
}

.sidebar-wrap .desc {
  margin-top: 12px;
}

.sub-force-graph {
  z-index: 1;
  position: relative;
}

.sub-graph-inputs .btn {
  margin: 4px;
}

.glossary-list li {
  margin-bottom: 12px;
}
