* {
  font-family: "roboto", sans-serif;
  color: #222;
}

:root {
  --shadow-color: 168deg 18% 57%;
  --shadow-elevation-low: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
    1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.34),
    2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.34),
    4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.34),
    7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.34),
    11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.34),
    17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.34),
    25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.34);
}

body {
  background: #d7d1e3;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

button a {
  color: white;
  text-decoration: none;
}

h1 {
  font-size: 40px;
  margin: 0;
}

p {
  font-size: 13px;
  margin-bottom: 13px;
  line-height: 1.5;
}

img {
  border-radius: 10px;
}

.modal-button {
  position: sticky;
  bottom: 10px;
  left: 12px;
}

button,
span.btn--secondary {
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  color: white;
  border: 0px;
  font-weight: bold;
}

button.btn {
  background: #52525b;
  color: white;
  text-decoration: none;
}

button.btn--primary {
  background: #02075d;
  color: white;
  text-decoration: none;
}

.btn--demo {
  margin-bottom: -22px !important;
}

button.btn--primary:hover {
  background: #00004d;
}

button.next-level-button,
.step-button,
.restart-button {
  margin: 4px 12px;
}

.btn--secondary.btn--nav {
  background: rgba(0, 0, 0, 0);
  border: 1px solid white;
}

.btn--nav:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: default;
}

button.btn--game-ui {
  background: #52525b;
}

.level-end-msg {
  font-size: 20px;
  max-width: 70%;
  margin: 8px auto;
  text-align: center;
}

.level-end-button-wrap {
  display: flex;
  justify-content: center;
}

.container {
  background: #fafafa;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 1400px;
  margin: 0 auto;
  /* margin: 12px; */
  padding: 12px 24px;
  overflow: auto;
}

.sub-graph-wrap > .container {
  padding-left: 0;
}

.game-wrap .container {
  max-height: 500px;
}

.header-wrap {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-gap: 8px; */
}

.header-text {
  grid-column: span 2;
}

.header-img {
  height: 100%;
  min-height: 300px;
  max-height: 350px;
  width: 33%;
  display: inline;
  float: right;
  object-fit: cover;
  margin-left: 12px;
  margin-right: -8px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2;
  overflow-x: auto;
  margin-top: 54px;
}

tspan {
  font-size: 12px;
}
