.nodes {
  stroke-width: 1;
  stroke: white;
}

.line-feeding {
  stroke: grey;
}

.line-faded {
  display: none;
}

@keyframes dropopacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}

.line-es {
  stroke: black;
}

.saved {
  stroke: #00c100;

  animation: hideshow 0.1s;
  animation-fill-mode: forwards;
}

@keyframes hideshow {
  from {
    stroke-width: 1;
  }
  to {
    stroke-width: 4;
  }
}

.dead {
  animation: killnode 0.2s;
  animation-fill-mode: forwards;
}

@keyframes killnode {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.sub-force-graph.container {
  z-index: 1;
  position: relative;
}
