p {
    margin-top: 0px;
}

img {
  max-width: 300px;
  width: 100%;
}

li {
  list-style: none;
}

.image-credits {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
}
