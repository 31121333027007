.level-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.levels {
    display:flex;
}

.levels button {
  margin: 4px;
  padding: 20px;
  font-size: 16px;
}

.levels button:hover {
  background: #41414a
}

.level {
    border: 1px solid black;
    padding: 20px;
    margin: 4px;
}
